.navbar {
  background-color: transparent;
}

.navbar-nav a {
  color: #212529 !important;
}

.navbar .container-fluid {
  justify-content: left !important;
}

.navbar-brand h4 {
  font-weight: 700;
  color: #212529;
}

.navbar-brand a {
  text-decoration: none;
}

.navbar-brand a:hover {
  text-decoration: none;
  opacity: 0.8;
}

.navbar-brand h4 span {
  color: #5b8352;
}

.navbar-toggler {
  font-size: 1rem;
  border: none !important;
}

.navbar-toggler:hover {
  opacity: 0.5;
}

.navbar-toggler:active,
.navbar-toggler:focus,
.navbar-toggler:visited,
.navbar-toggler:focus-visible {
  opacity: 1;
  border: none !important;
  outline: none !important;
}

.offcanvas-title h4 {
  font-weight: 700;
  color: #212529;
}

.offcanvas-title h4 span {
  color: #5b8352;
}

.offcanvas-body .nav-link {
  margin-bottom: 0px;
  padding: 12px;
}

.offcanvas-body .nav-link:hover {
  padding-left: 16px;
  background-color: #e0e3e7;
  color: #000 !important;
}

.menu-scroll-gradient {
  display: block;
  position: absolute;
  bottom: 5vh;
  right: 0;
  height: 40px;
  width: 100%;
  z-index: 10;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

@media screen and (min-width: 992px) {
  .hero-img {
    height: 100vh;
    margin-top: -94px;
  }

  .hero-text h1 {
    font-size: 5rem;
    line-height: 5.6rem;
  }
}
