.chapters-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0px;
  height: 300px;
}

.chapters-text {
  height: 100%;
}

.text-wrapper {
  height: 90vh;
  overflow-y: scroll;
  padding: 16px 24px;
  position: relative;
}

.scroll-gradient {
  position: absolute;
  bottom: 0vh;
  height: 80px;
  width: 100vw;
  z-index: 10;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(245, 248, 249, 1),
    rgba(245, 248, 249, 1)
  );
}

@media screen and (min-width: 992px) {
  .chapters-img {
    height: 100vh;
    margin-top: -94px;
  }

  .chapters-text {
    height: 90vh;
  }

  .text-wrapper {
    overflow-y: scroll;
    height: 80vh;
    padding: 10vh 20%;
  }

  .scroll-gradient {
    bottom: 5vh;
    width: 50vw;
    height: 100px;
  }
}
