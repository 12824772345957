.hero-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../public/assets/bg.jpg);
  margin-top: 0px;
  height: 50vh;
}

.hero-text {
  padding: 40px 20px !important;
}

.hero-text h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 3.8rem;
}

.hero-text h1 span {
  color: #5b8352;
}

.logos {
  justify-content: space-between;
}

.logos div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer !important;
}

.bajo {
  position: relative;
  text-align: center;
  align-items: center;
}

.bajo img {
  height: auto !important;
}

@media screen and (min-width: 992px) {
  .hero-img {
    height: 100vh;
    margin-top: -94px;
  }

  .hero-text {
    padding: 10vh 5% !important;
  }

  .hero-text h1 {
    font-size: 5rem;
    line-height: 5.6rem;
  }
}

@media screen and (min-width: 1200px) {
  .hero-text {
    padding: 10vh 10% 8vh !important;
  }

  .hero-text h1 {
    font-size: 6rem;
    line-height: 5.7rem;
  }
}
