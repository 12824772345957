.char-list-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../public/assets/bg.jpg);
  margin-top: 0px;
  height: 50vh;
}

.char-list-text {
  padding: 30px 20px 0px !important;
  position: relative;
  overflow: hidden;
}

ul.char-hero-list {
  overflow-y: scroll;
  padding-bottom: 80px;
}

ul.char-hero-list li {
  list-style: none;
  padding: 16px;
}

ul.char-hero-list li:hover {
  padding-left: 24px;
  background-color: #e0e3e7;
}

ul.char-hero-list li a {
  text-decoration: none;
  color: #212529;
  font-size: 1.2rem;
}

.char-hero-list {
  height: 68vh;
}

.scroll-gradient-chapters {
  position: absolute;
  bottom: 0vh;
  height: 80px;
  width: 100%;
  z-index: 10;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(245, 248, 249, 1)
  );
}

@media screen and (min-width: 992px) {
  .char-list-img {
    height: 100vh;
    margin-top: -94px;
  }

  .char-list-text {
    padding: 5vh 5% 0px !important;
  }

  .scroll-gradient-chapters {
    height: 100px;
    width: 100%;
    bottom: 5vh;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(245, 248, 249, 1)
    );
  }
}

@media screen and (min-width: 1200px) {
  .char-list-text {
    padding: 5vh 10% 0px !important;
  }
}
