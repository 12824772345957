body {
  background-color: #f5f8f9 !important;
}

.btn-link {
  color: #5b8352 !important;
}

.btn-link:hover {
  color: #35512e !important;
}

.bg-body-secondary {
  padding: 24px;
  margin-bottom: 24px;
}

h6 {
  font-weight: 700 !important;
}

a {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  color: #5b8352 !important;
}

a:hover {
  color: #35512e !important;
}

tbody {
  vertical-align: text-top;
}

/* App */

.App {
  max-height: 100vh !important;
  overflow: hidden;
}
